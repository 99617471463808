import React, { ReactElement, useEffect, useState, useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as actions from '@redux-actions/';
import { CardDisplayProps, defaultCardDisplayProps } from './CardDisplayUtils';
import DetailsSingleActivityButton from '../DetailsSingleActivityButton';
import { Course, CourseLanguage, CoursesMap } from '@model/CoursesClass';
import AnimationExpand from '../animations/AnimationExpand';
import {
  isCompletedCourse,
  isLearningPath,
  isLivestreamCourse,
  isLpEnrolled,
  scrollCourseDetailToTop,
  truncateLabel,
  handleOnEnterKeyPress,
  isMultiActivity,
  getCoursesWithinLP,
  isStartedCourse,
  isAnEvent,
  isAnEmptyVirtualClassroom,
  isAnEmptyLivestream,
  showPrimaryOrSecondaryBtn,
} from '@utility/Api';
import CardCover from './CardCover';
import CardLoader from '@components/UI/CardLoader';
import ButtonV2 from '@components/UI/ButtonV2';
import Close from '@images/svg-icons/close.svg';
import VolumeIcon from '@images/svg-icons/volume.svg';
import NoVolumeIcon from '@images/svg-icons/no-volume.svg';
import PlayIcon from '@images/svg-icons/play.svg';
import PauseIcon from '@images/svg-icons/pause.svg';
import usePreview from '@hooks/usePreview';
import { UserProfile } from '@model/User';
import { UtagViewPages } from '@components/link-utils';
import NotYetAvailableIcon from '@images/svg-icons/not-yet-available.svg';
import LiveIcon from '@images/ctype-icons/live.svg';
import { willBeNotifiedRecording, willLivestreamNotRecorded } from '@utility/LivestreamUtility';
import {
  canCourseBeAccessed,
  isPurchasable,
  isPurchased,
  isPurchasedIndividually,
} from '@utility/ecommerceUtility';
import BuyLicenseSection from '@components/CourseOverview/BuyLicenseSection';
import Accordion from '@components/UI/Accordion';
import { RootState } from 'src/store/store';
import CartIcon from '@components/CartIcon';
import MultiactivityCompletionPercentage from '@components/UI/MultiactivityCompletionPercentage';
import { useSelector, useDispatch } from '@hooks/redux';
import WishlistIcon from '@components/WishlistIcon';
import LazySVG from '@components/LazySvg';
import RibbonCourseEvolution from '@components/UI/RibbonCourseEvolution';
import { hasCertificates, isCourseEl360 } from '@utility/CourseUtility';
import useDownloadCertificate from '@hooks/useDownloadCertificate';
import { ButtonHarvard, isCourseHarvard } from '@components/Harvard/Harvard';
import { isUserEl360 } from '@utility/userUtility';
import TextSkeleton from '@components/skeletons/TextSkeleton';
import { useBreakpointBoolean } from '@hooks/createBreakpoint';
import { isPersonificationOn } from "../../pages_v3/UserGroupSimulation/UserGroupSimulation_PersonificateLogic";
import { buildClickDataTrackingObject, UserTypes } from '@model/TrackingClass';
import { camelCase } from 'lodash';
import { setIsLoadedGetUserRelevantSessionsById } from '@redux-actions/'; 
import { useHistory } from 'react-router-dom';
import { USER_URLS } from '@components/link-utils';
import IconCtaAssignToTeam from '@svg-icons/manager-assignment/Community.svg'
import clsx from '@utility/clsx';
import { isUserManager } from '@utility/ManagerAssignment/managerAssignment.tsx';

interface CardOverviewProps extends CardDisplayProps {
  compatibility: number;
  course: Course;
  showLaunchButton: boolean;
  isLoadingDetails: boolean;
  isMandatory: boolean;
  catalogTypes: Array<string>;
  previewVideoUrl: string;
  userProfile: UserProfile;
  timeOnTraining: string;
  isTimeOnTrainingLoaded: boolean;
  subtitle: CourseLanguage;
  enrollLp: (enroll: boolean, id: string) => void;
  isLpEnrolling: boolean;
  toggleModalCourseById: (courseId: string) => void;
  isLoadingCourse: { [courseId: string]: boolean };
  setShowShareModal: (showShareModal: boolean) => void;
  noLazyLoadImage?: boolean;
}

const CardOverview = (props: PropsFromRedux & CardOverviewProps): ReactElement => {
  const {
    lang,
    courseIdMaster,
    courseFullName,
    image,
    ctypeName,
    fallbackImage,
    previewVideoUrl,
    isLoadingCourse,
    setShowShareModal,
    noLazyLoadImage
  } = props;
  const showIframe = useSelector(state => state.utils.showIframe);

  const {isPortrait,isPhone,isTablet,isDesktop} = useBreakpointBoolean();

  const closePreviewButtonRef = useRef<HTMLButtonElement>(null);
  const [
    previewElement,
    showPreview,
    setShowPreview,
    muted,
    setMuted,
    videoPaused,
    setVideoPaused,
    toggleVideo,
    previewFallback,
    onVideoPreviewError,
  ] = usePreview();
  const coursesMap: CoursesMap = useSelector(state => state.course.coursesMap);
  const isLoadingGetUserRelevantSessionById = useSelector(state => state.utils.isLoadingGetUserRelevantSessionsById);
  const isLoadedGetUserRelevantSessionById = useSelector(state => state.utils.isLoadedGetUserRelevantSessionsById);
  const dispatch = useDispatch();
  const userProfile = useSelector(state => state.user.userProfile);

  useEffect(() => {
    if (!isLoadingGetUserRelevantSessionById && !isLoadedGetUserRelevantSessionById) {
      dispatch(setIsLoadedGetUserRelevantSessionsById(true));
    }
  }, [isLoadingGetUserRelevantSessionById, isLoadedGetUserRelevantSessionById, dispatch]);

  useEffect(() => {
    if (showIframe) {
      // if iframe is true we muted the video
      setMuted(true);
    }
  }, [showIframe]);

  const handleOpenIframe = (url: string, urlToCheck: string, isVideo: boolean) => {
    if (!videoPaused) {
      toggleVideo();
    }
    props.openIframe(url, urlToCheck, isVideo);
  };

  const handleShowPreview = (show: boolean) => {
    if (show) {
      //scroll to top
      scrollCourseDetailToTop('smooth');
    }
    if (!show) {
      setMuted(true);
      if (videoPaused) {
        toggleVideo();
      }
    }
    setShowPreview(show);
  };

  const userEl360 = isUserEl360(props.userProfile);
  const courseEl360 = isCourseEl360(props.course);

  // useEffect(() => {
  //   if (props.userProfile && ctypeName?.length > 0 && props.isTimeOnTrainingLoaded) {
  //     // console.log(`cardOverview`, {
  //     //   ...UtagViewPages.PRODUCT,
  //     //   Products_Category_Array: props?.catalogTypes,
  //     //   // product_subcategory
  //     //   Products_Id_Array: [courseIdMaster],
  //     //   Products_ModelName_Array: [courseFullName],
  //     //   product_format: typeof ctypeName === 'string' ? [ctypeName] : ctypeName,
  //     //   Products_Mandatory_Array: props?.isMandatory ? ['Y'] : ['N'],
  //     //   Page_Language: props.userProfile?.preferredLang,
  //     //   Page_Country: props.userProfile?.country,
  //     //   User_LoginStatus: props.userProfile?.senderSystem,
  //     //   User_Id: props.userProfile?.username,
  //     //   user_timeontraining: props.timeOnTraining,
  //     //   Page_DeviceType: 'web',
  //     //   Order_Currency: props.userProfile?.ecommerceInfo?.currency,
  //     //   User_MoodleID: props.userProfile?.moodleId,
  //     // });
  //     // window?.utag?.view({
  //     //   ...UtagViewPages.PRODUCT,
  //     //   Products_Category_Array: props?.catalogTypes,
  //     //   // product_subcategory
  //     //   Products_Id_Array: [courseIdMaster],
  //     //   Products_ModelName_Array: [courseFullName],
  //     //   product_format: typeof ctypeName === 'string' ? [ctypeName] : ctypeName,
  //     //   Products_Mandatory_Array: props?.isMandatory ? ['Y'] : ['N'],
  //     //   Page_Language: props.userProfile?.preferredLang,
  //     //   Page_Country: props.userProfile?.country,
  //     //   User_LoginStatus: props.userProfile?.senderSystem,
  //     //   User_Id: props.userProfile?.username,
  //     //   user_timeontraining: props.timeOnTraining,
  //     //   Page_DeviceType: 'web',
  //     //   Order_Currency: props.userProfile?.ecommerceInfo?.currency,
  //     //   User_MoodleID: props.userProfile?.moodleId,
  //     // });
  //   }
  // }, [courseIdMaster, props.isTimeOnTrainingLoaded]);

  useEffect(() => {
    if (showPreview) {
      closePreviewButtonRef?.current?.focus();
    }
  }, [showPreview]);

  const [handleDownloadClick, shouldShowDownloadButton] = useDownloadCertificate(
    props.course,
    false
  );

  const history = useHistory();

  const handleAssignToTeamCta = () => {
    // GO TO ASSIGN TO YOUR TEAM PAGE
    history.push({
      pathname: USER_URLS.ASSIGN_TO_YOUR_TEAM_LOADING.URL,
      state: { courseId: props?.course?.courseIdMaster }
    });
    // ANALYTICS
    buildClickDataTrackingObject(
      "assignThisToYourTeam", props?.course?.courseIdMaster
    )
  }

  const launchButtonContainerClassName = clsx('card-v3__launch-button-container', {
    'preview-space-between': !isPurchased(props.course) && previewVideoUrl && !previewFallback && !userEl360 && !courseEl360 && !isUserManager(userProfile?.role),
  });

  return (
    <>
      <div
        className={
          'card-v3 card-v3-overview' + (showPreview ? ' card-v3-overview__preview-open' : '')
        }
        data-id={courseIdMaster}
        ref={props?.cardRef}
      >
        <div className={`card-v3__wrapper`}>
          {!isLoadingGetUserRelevantSessionById && isLoadedGetUserRelevantSessionById && (            
          <RibbonCourseEvolution
            course={props.course}
            useBadgeLabelLive={true}
            ribbonInfo={{
              label: props.badgeLabel,
              color: props.badgeColor,
              priority: props.badgePriority,
            }}
            useTagBadge={false}
          />
          )}
          {props.course && !props.course.isStatic && (
            <div className="card-v3__icons-top-right">
              <WishlistIcon
                isWishlist={props.isWishlist}
                isLoadingWishlistLocal={props.isLoadingWishlistLocal}
                isNotYetAvailable={false}
                enableWishlist={props.enableWishlist}
                toggleWishlist={props.toggleWishlist}
                isStatic={props.isStatic}
              />

              {previewVideoUrl && !previewFallback && (
                <>
                  <ButtonV2
                    variant="icon-btn"
                    small
                    className="card-v3__icons-top-right__play-icon mobile"
                    handleClick={() => handleShowPreview(true)}
                  >
                    <LazySVG
                      src="/images/svg-icons/play-2.svg"
                      alt={
                        isLearningPath(props.course)
                          ? lang.COURSE_TRAILER_MOBILE
                          : lang.PREVIEW_BUTTON_MOBILE
                      }
                    />
                  </ButtonV2>
                  <span className="card-v3__icons-top-right__play-text mobile">
                    {(isLearningPath(props.course)
                      ? lang.COURSE_TRAILER_MOBILE
                      : lang.PREVIEW_BUTTON_MOBILE
                    )?.toUpperCase()}
                  </span>
                </>
              )}
            </div>
          )}

          {!previewVideoUrl || previewFallback ? (
            <CardCover
              variant="vertical"
              image={image}
              courseFullName={courseFullName}
              fallbackImage={fallbackImage}
              // forceLoader={isLoadingCourse?.[courseIdMaster]}
              noLazyLoadImage={noLazyLoadImage}
            />
          ) : (
            <>
              <video
                autoPlay
                loop
                muted={muted}
                className={
                  'card-v3__background card-v3__video ' +
                  (showPreview ? 'card-v3-overview__video' : '')
                }
                ref={previewElement}
                src={previewVideoUrl}
                onPlay={() => setVideoPaused(false)}
                onPause={() => setVideoPaused(true)}
                webkit-playsinline="true"
                playsInline
                aria-label={courseFullName + ' video'}
                onError={onVideoPreviewError}
              />
              {!showPreview && <div className="card-v3__shadow phone-and-tablet-portrait" />}
              {showPreview && (
                <>
                  <button
                    className={
                      'card-v3-overview__video-buttons card-v3-overview__close-preview button-no-back'
                    }
                    onClick={() => handleShowPreview(false)}
                    ref={closePreviewButtonRef}
                  >
                    <Close />
                  </button>
                  <button
                    className={
                      'card-v3-overview__video-buttons card-v3-overview__volume button-no-back'
                    }
                    onClick={() => setMuted(!muted)}
                  >
                    {muted ? <NoVolumeIcon /> : <VolumeIcon />}
                  </button>
                  <button
                    className={
                      'card-v3-overview__video-buttons card-v3-overview__play button-no-back'
                    }
                    onClick={() => toggleVideo()}
                  >
                    {videoPaused ? <PlayIcon /> : <PauseIcon />}
                  </button>
                </>
              )}
            </>
          )}

          <div className="card-v3__cover desktop">
            {/* {(props.course && !props.course.isStatic && compatibility > 0) &&
            <div className="card-v3__compatibility">
              <span className="text-14-medium">{lang && lang.PERCENT_COMPATIBLE.replace('{percent}', compatibility.toString())}</span>
            </div>
          } */}
            <div
              className={`width-100 flex justify-between ${
                (isMultiActivity(props.course) &&
                  props.course.numActivities?.totalActivities > 0) ||
                isLearningPath(props.course)
                  ? 'column'
                  : ''
              }`}
            >
              <div className="card-v3__launch-button">
                {previewVideoUrl && !previewFallback && (
                  <ButtonV2
                    variant="secondary"
                    small
                    className="card-v3-overview__button-preview"
                    handleClick={() =>{buildClickDataTrackingObject("preview");handleShowPreview(true)}}
                  >
                    {isLearningPath(props.course) ? lang.COURSE_TRAILER : lang.PREVIEW_BUTTON}
                  </ButtonV2>
                )}
              </div>

              {props.isLiveStream && (
                <span className={`card-v3__icon ${props.isLiveNow ? 'red' : ''}`}>
                  <LiveIcon />
                </span>
              )}

              {isMultiActivity(props.course) &&
                props.course.numActivities &&
                (!isPurchasable(props.course) || isPurchased(props.course)) && (
                  <CardOverviewProgressBar
                    course={props.course}
                    showBar={props.showBar}
                    valueBar={props.valueBar}
                  />
                )}

              {isLearningPath(props.course) && (
                <CardOverviewProgressBar
                  course={props.course}
                  showBar={true}
                  valueBar={props.valueBar}
                />
              )}
            </div>
          </div>

          <div
            className={'card-v3__cover mobile ' + (isCourseHarvard(props.course) ? 'harvard' : '')}
            role="region"
            aria-label={props.course.courseFullName}
          >
            <div className="width-100">
              {/* {(props.course && !props.course.isStatic && compatibility > 0) &&
              <div className="card-v3__compatibility">
                <span className="text-14-medium">{lang && lang.PERCENT_COMPATIBLE.replace('{percent}', compatibility.toString())}</span>
              </div>
            } */}
              <div
                className={`card-v3__icon ${props.isLiveNow && props.isLiveStream ? 'red' : ''}`}
              >
                {/* {icon}
              {!isLivestreamCourse(props.course) &&
                <span className="card-v3__bottom-info-text">{truncateLabel(ctypeName, 41)}</span>
              } */}
                {!isAnEvent(props?.course) && (
                  <span className="card-v3__bottom-info-text">{truncateLabel(ctypeName, 41)}</span>
                )}
              </div>
              {props.summary && !props.isLoadingCourse?.[props.course.courseIdMaster] ? (
                <div
                  className={'card-v3__title' + (courseFullName.length > 40 ? ' smaller-font' : '')}
                >
                  <span title={courseFullName}>{truncateLabel(courseFullName, 50)}</span>
                </div>
              ) : (isTablet? 
                <>
                  <TextSkeleton
                    width={400}
                    height={20}
                    borderRadius={5}
                    className="skeleton-text-desk"
                  />
                </>
                :<>
                    <TextSkeleton
                    width={200}
                    height={20}
                    borderRadius={5}
                    className="skeleton-text-desk"
                  />
                                      <TextSkeleton
                    width={100}
                    height={20}
                    borderRadius={5}
                    className="skeleton-text-desk"
                  />
                </>
              )}

              <div className="text-16 text-live-date-label">
                {/* {isLivestreamCourse(props.course) &&
                <BottomLabelLive
                  {...getLivestreamDate(props.course)}
                  variant="overview"
                  lang={lang}
                  showDate
                  showLiveBadge={props.course.showLiveBadge}
                  showLiveCountdown={props.course.showLiveCountdown}
                />
              } */}
                {willLivestreamNotRecorded(props.course, props.userProfile) && (
                  <span className="text-16 session-not-recorded">{lang.SESSION_NOT_RECORDED}</span>
                )}
                {(isAnEmptyVirtualClassroom(props.course) || isAnEmptyLivestream(props.course)) &&
                  <div>
                    <div className="top-mobile__emptyvc-comingsoon">{lang.EMPTY_VC_COMINGSOON}</div>
                    <div className="top-mobile__emptyvc-description">{lang.EMPTY_VC_DESCRIPTION}</div>
                  </div>
                }
              </div>
              {(!isAnEmptyVirtualClassroom(props.course) && !isAnEmptyLivestream(props.course)) &&
              <div
                className={
                  'top-mobile__cta-row' +
                  (shouldShowDownloadButton ? ' download-share__wrapper' : '')
                }
              >
                {shouldShowDownloadButton && (
                  <div className="download-share">
                    <div className="award">
                      <LazySVG src="/images/svg-icons/award.svg" alt={'award'} />
                    </div>
                    <div className="award-label">
                      <div>
                        <p>{lang.CONGRATULATIONS}</p>
                        <p>{lang.COMPLETED_CONTENT}</p>
                      </div>
                      <div className="completed-course__buttons">
                        <ButtonV2
                          className="completed-course__certificate"
                          variant="text-btn-no-arrow"
                          ariaLabel={
                            lang.CTA_DOWNLOAD_CERTIFICATE + ' ' + props.course.courseFullName
                          }
                          handleClick={(e)=> {buildClickDataTrackingObject("download_pdf",camelCase(props?.course?.courseId));handleDownloadClick(e)}}
                        >
                          <LazySVG
                            className="area-of-expertise__l1-channel-arrow"
                            src={'/images/svg-icons/download-certificate.svg'}
                            alt={lang.CTA_DOWNLOAD_CERTIFICATE}
                          />
                          {lang.CTA_DOWNLOAD_CERTIFICATE}
                        </ButtonV2>
                        <ButtonV2
                          className="completed-course__certificate"
                          variant="text-btn-no-arrow"
                          ariaLabel={lang.CTA_SHARE + ' ' + props.course.courseFullName}
                          handleClick={()=> {buildClickDataTrackingObject("shareLinkedin",camelCase(props?.course?.courseId));handleDownloadClick}}
                        >
                          <LazySVG
                            className="area-of-expertise__l1-channel-arrow"
                            src={'/images/svg-icons/plus2.svg'}
                            alt={lang.CTA_SHARE}
                          />
                          {lang.CTA_SHARE}
                        </ButtonV2>
                      </div>
                    </div>
                  </div>
                )}
                {canCourseBeAccessed(props.course) &&
                  !willBeNotifiedRecording(props.course, props.userProfile) && (
                    <div className="card-v3__launch-button">
                      <div
                        className={
                          'card-v3__launch-button-row ' +
                          (isPurchased(props.course) ? 'purchased' : '') +
                          (userEl360 && courseEl360 ? 'purchased el360-program' : '')
                        }
                      >
                        {isPurchased(props.course) && (
                          <span className="card-v3__text-purchased">
                            {isPurchasedIndividually(props.course, props.userProfile)
                              ? lang.PURCHASED
                              : lang.INCLUDED_IN_YOUR_PLAN}
                          </span>
                        )}

                        {userEl360 && courseEl360 && (
                          <span className="card-v3__text-el360-program">
                            {lang.EL360_INCLUDED_IN_EL360_PROGRAM_LABEL}
                          </span>
                        )}

                        <div
                          className={launchButtonContainerClassName}
                        >

                          {props.showLaunchButton ? (
                            isCourseHarvard(props.course) ? (
                              <ButtonHarvard
                                textCTA={lang.LESSON_HARVARD_CTA}
                                linkBtnExternal="https://leonardo.essilorluxottica.com/"
                                course={props.course}
                                tooltip={lang.LESSON_HARVARD_TOOLTIP}
                                tooltipToLeft={true}
                                isExternalAction={true}
                              />
                            ) : (
                              <DetailsSingleActivityButton
                                className={
                                  showPreview && previewVideoUrl
                                    ? 'card-v3-overview__preview-open'
                                    : ''
                                }
                                lang={lang}
                                buttonVariant={showPrimaryOrSecondaryBtn(shouldShowDownloadButton, userEl360, courseEl360)}
                                course={props.course}
                                subtitle={props.subtitle}
                                isLoading={false}
                              />
                            )
                          ) : (
                            <>
                              {props.isLoadingDetails[courseIdMaster] && (
                                <div className="launch-spinner">
                                  <CardLoader />
                                </div>
                              )}
                            </>
                          )}

                          {/* ASSIGN TO YOUR TEAM  */}
                          {isUserManager(userProfile?.role) && props?.course?.managerAssignment &&
                            <ButtonV2
                              small
                              variant="secondary"
                              className={'cta-assign-to-team'}
                              handleClick={handleAssignToTeamCta}
                            >
                              <div className='icon-container'>
                                <IconCtaAssignToTeam className={`cta-assign-to-team`} />
                              </div>
                              <div className='text-container'>
                                <span className='cta-text'>{lang.CTA_ASSIGN_TO_TEAM_PDP}</span>
                              </div>
                            </ButtonV2>
                          }
                        </div>
                      </div>
                    </div>
                  )}
                  
              </div>
              }

              {canCourseBeAccessed(props.course) &&
                !!willBeNotifiedRecording(props.course, props.userProfile) && (
                  <span className="card-v3-overview__will-be-notified-recording">
                    {lang.WILL_BE_NOTIFIED_RECORDING_LIVESTREAM}
                  </span>
                )}

              {canCourseBeAccessed(props.course) && props.isParentLpBlocked && (
                <div className="card-v3-overview__blockedby-row">
                  <NotYetAvailableIcon className="card-v3-overview__blockedby-lock" />
                  <div className="card-v3-overview__blockedby-text">
                    <span className="text-14">{lang.ENROLL_AFTER_COMPLETING}</span>
                    {props.blockingLps.map(blockingLp => (
                      <span
                        key={blockingLp.courseIdMaster}
                        className="text-14 card-v3-overview__blocking-lp"
                        onClick={() => props.toggleModalCourseById(blockingLp.courseIdMaster)}
                      >
                        {blockingLp.courseFullName}
                      </span>
                    ))}
                  </div>
                </div>
              )}

              {isPurchasable(props.course, true) && !isPurchased(props.course) && (
                <BuyLicenseSection
                  course={props.course}
                  variant="card-overview"
                  showPreview={previewVideoUrl && !previewFallback}
                  handleShowPreview={() => handleShowPreview(true)}
                  labelPreview={
                    isLearningPath(props.course) ? lang.COURSE_TRAILER : lang.PREVIEW_BUTTON
                  }
                  isStartedCourse={isStartedCourse(props.course)}
                />
              )}

              {(!isPurchasable(props.course) ||
                isPurchased(props.course) ||
                props.valueBar > 0 ||
                (isMultiActivity(props.course) && props.course.numActivities)) && (
                <CardOverviewProgressBar
                  course={props.course}
                  showBar={props.showBar}
                  valueBar={props.valueBar}
                />
              )}
              {isPurchased(props.course, true) && (
                <div className="accordion-more-licenses-space-allocation"></div>
              )}
            </div>

            <div className="card-v3__shadow" />
          </div>
        </div>
        {isPurchased(props.course, true) && (
          <Accordion
            title={lang.BUY_MORE_LICENSES}
            className="accordion-more-licenses border-bottom card-overview phone-and-tablet-portrait"
            trackTransition={true}
          >
            <BuyLicenseSection
              course={props.course}
              variant="card-overview"
              buyNowVariant="secondary"
            />
          </Accordion>
        )}
      </div>
    </>
  );
};

const CardOverviewProgressBar = props => {
  const [completedCourseNumber, setCompletedCourseNumber] = useState<number>(0);
  const coursesMap: CoursesMap = useSelector(state => state.course.coursesMap);
  const lpCourses = getCoursesWithinLP(props.course, coursesMap);

  useEffect(() => {
    if (lpCourses) {
      let number = 0;
      lpCourses.forEach(course => {
        if (isCompletedCourse(course)) {
          number = number + 1;
        }
        setCompletedCourseNumber(number);
      });
    }
  }, [lpCourses]);

  return (
    (!isAnEmptyVirtualClassroom(props.course) && !isAnEmptyLivestream(props.course)) &&
    <div
      className={`card-v3__status ${
        isMultiActivity(props.course) &&
        !!props.course.numActivities?.totalActivities &&
        !isAnEvent(props.course)
          ? 'multiactivity'
          : ''
      }`}
    >
      {isMultiActivity(props.course) &&
        props.course.numActivities?.totalActivities > 0 &&
        !isAnEvent(props.course) && (
          <MultiactivityCompletionPercentage
            completedActivities={props.course.numActivities.completedActivities}
            totalActivities={props.course.numActivities.totalActivities}
            isCompleted={
              props.course.numActivities.completedActivities > 0 &&
              props.course.numActivities.completedActivities ===
                props.course.numActivities.totalActivities
            }
          />
        )}

      {isLearningPath(props.course) && !isAnEvent(props.course) && (
        <MultiactivityCompletionPercentage
          completedActivities={completedCourseNumber}
          totalActivities={lpCourses?.length}
          isCompleted={completedCourseNumber > 0 && completedCourseNumber === lpCourses?.length}
        />
      )}

      {props.showBar && !isAnEvent(props.course) && (
        <div className="card-v3__bar">
          <AnimationExpand
            key={props.valueBar}
            initialWidth="0"
            finalWidth={props.valueBar + '%'}
            animationEase="easeOut"
          ></AnimationExpand>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    lang: state.utils.lang,
    isLoadingWishlist: state.course.isLoadingWishlist,
    isLoadingDetails: state.course.isLoadingDetails,
    timeOnTraining: state.course.timeOnTraining,
    isTimeOnTrainingLoaded: state.course.isTimeOnTrainingLoaded,
    userProfile: state.user.userProfile,
    isLpEnrolling: state.course.isLpEnrolling,
    isLoadingCourse: state.course.isLoadingCourse,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    enrollLp: (enroll, id) => dispatch(actions.enrollLp(enroll, id)),
    toggleModalCourseById: courseId => dispatch(actions.toggleModalCourseById(courseId)),
  };
};

CardOverview.defaultProps = {
  ...defaultCardDisplayProps,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CardOverview);
