import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Course } from '@model/CoursesClass';
import CarouselSection from '@components/CarouselSection';
import { useSelector } from '@hooks/redux';
import useGetOnboardingRMCourses from '@hooks/onboarding/useGetOnboardingRMCourses';
import useGetOnboardingCourse from '@hooks/onboarding/useGetOnboardingCourse';

interface RelatedMandatorySectionProps {
  course: Course;
}

const RelatedMandatorySection = ({ course }: RelatedMandatorySectionProps) => {
  const lang = useSelector(state => state.utils.lang);

  const onboardingRelatedMandatoryCourses = useGetOnboardingRMCourses();
  const filteredCourses = onboardingRelatedMandatoryCourses?.filter(
    rmCourse => rmCourse.courseId !== course.courseId
  );

  const showOnboardingRMCarousel = filteredCourses?.length > 0 && filteredCourses?.some(course => course && Object.keys(course)?.length > 0);
  const isGetCourseCompleted: boolean = useSelector(state => state.course.isGetCourseCompleted);
  const isLoadingCourses = !isGetCourseCompleted;
  const hasOnboardingCourse = useGetOnboardingCourse();

  return (
    <>
      {/* Onboarding Related Mandatory Content Carousel */}
      {showOnboardingRMCarousel && !!hasOnboardingCourse && ( 
        <div className='onboarding-related-mandatory-carousel' id='related-mandatory-content_carousel'>
          <div className='onboarding-related-mandatory-carousel-wrapper'>
            <CarouselSection
              courses={filteredCourses}
              lang={lang}
              title="ONBOARDING_RELATED_MANDATORY_CAROUSEL_TITLE"
              description="ONBOARDING_RELATED_MANDATORY_CAROUSEL_DESCRIPTION"
              classNames="carousel-max-width"
              variableWidth
              isLoading={isLoadingCourses}
              />
            </div>
          </div>
      )}
    </>
  );
};

export default RelatedMandatorySection;
