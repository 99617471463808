import React, { CSSProperties, ReactNode } from 'react';
import ArrowLeftIcon from '@svg-icons/arrow-left6.svg';
import ArrowLongLeftIcon from '@svg-icons/arrow-left6.svg';
import ArrowRightIcon from '@svg-icons/arrow-right6.svg';
import ArrowLongRightIcon from '@svg-icons/arrow-right6.svg';
import ArrowUpIcon from '@svg-icons/arrow-up.svg';
import ArrowDownIcon from '@svg-icons/arrow-down.svg';
import ArrowHeadUpIcon from '@svg-icons/arrowhead-up.svg';
import ArrowHeadDownIcon from '@svg-icons/arrowhead-down.svg';
import ArrowHeadLeftIcon from '@svg-icons/arrowhead-left.svg';
import ArrowHeadRightIcon from '@svg-icons/arrowhead-right.svg';
import PlayIcon from '@svg-icons/play-2.svg';
import clsx from '@utility/clsx';
import { useSelector } from '@hooks/redux';
import useLangAuth from '@hooks/useLangAuth';
import { Link } from 'react-router-dom';
import LazySVG from '@components/LazySvg';
import { Percentage } from 'src/stories/cards/CardProgress.stories';

export type Props = {
  loading?: boolean;
  handleClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  children: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  variant?:
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'tertiary-vtransparent'
  | 'text-btn'
  | 'text-btn2'
  | 'text-btn-no-arrow'
  | 'text-btn-dotted'
  | 'play-btn'
  | 'icon-btn'
  | 'outline-btn'
  | 'counter-btn'
  | 'overlay-btn';
  small?: boolean;
  title?: string;
  ariaLabel?: string;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  to?: string;
  target?: string;
  rel?: string;
  tabIndex?: number;
  forceFocus?: boolean;
  variantLongArrow?: boolean;
  leadingIcon?: boolean;
  forwardRef?;
  ['data-element-id']?: string;
  ['data-description']?: string;
  hideLoadingLabel?: boolean;
  numberCounter?: number;
  addStopPropagation?: boolean;
  showArrowLeft?: boolean;
  showArrowRight?: boolean;
  showArrowAfterLeft?: boolean;
  showArrowAfterRight?: boolean;
  showArrowAfterUp?: boolean;
  showArrowAfterDown?: boolean;
  showArrowAfterHeadLeft?: boolean;
  showArrowAfterHeadRight?: boolean;
  showArrowAfterHeadUp?: boolean;
  showArrowAfterHeadDown?: boolean;
  showArrowBeforeLeft?: boolean;
  showArrowBeforeRight?: boolean;
  showArrowBeforeUp?: boolean;
  showArrowBeforeDown?: boolean;
  showArrowBeforeHeadLeft?: boolean;
  showArrowBeforeHeadRight?: boolean;
  showArrowBeforeHeadUp?: boolean;
  showArrowBeforeHeadDown?: boolean;
  percentage?: number;
};

interface TextBtnWrapperProps {
  children: React.ReactNode;
  variantLongArrow?: boolean;
  leadingIcon?: boolean;
  showArrowAfterLeft?: boolean;
  showArrowAfterRight?: boolean;
  showArrowAfterUp?: boolean;
  showArrowAfterDown?: boolean;
  showArrowAfterHeadLeft?: boolean;
  showArrowAfterHeadRight?: boolean;
  showArrowAfterHeadUp?: boolean;
  showArrowAfterHeadDown?: boolean;
  showArrowBeforeLeft?: boolean;
  showArrowBeforeRight?: boolean;
  showArrowBeforeUp?: boolean;
  showArrowBeforeDown?: boolean;
  showArrowBeforeHeadLeft?: boolean;
  showArrowBeforeHeadRight?: boolean;
  showArrowBeforeHeadUp?: boolean;
  showArrowBeforeHeadDown?: boolean;
}

const getArrowIcon = ({
  variantLongArrow,
  showArrowLeft,
  showArrowRight,
  showArrowUp,
  showArrowDown,
  showArrowHeadLeft,
  showArrowHeadRight,
  showArrowHeadUp,
  showArrowHeadDown,
}: {
  variantLongArrow: boolean;
  showArrowLeft: boolean;
  showArrowRight: boolean;
  showArrowUp: boolean;
  showArrowDown: boolean;
  showArrowHeadLeft: boolean;
  showArrowHeadRight: boolean;
  showArrowHeadUp: boolean;
  showArrowHeadDown: boolean;
}) => {
  switch (true) {
    case showArrowLeft:
      return variantLongArrow ? <ArrowLongLeftIcon /> : <ArrowLeftIcon />;
    case showArrowRight:
      return variantLongArrow ? <ArrowLongRightIcon /> : <ArrowRightIcon />;
    case showArrowUp:
      return <ArrowUpIcon />;
    case showArrowDown:
      return <ArrowDownIcon />;
    case showArrowHeadLeft:
      return <ArrowHeadLeftIcon />;
    case showArrowHeadRight:
      return <ArrowHeadRightIcon />;
    case showArrowHeadUp:
      return <ArrowHeadUpIcon />;
    case showArrowHeadDown:
      return <ArrowHeadDownIcon />;
    default:
      return null;
  }
};


const ButtonV2 = ({
  loading,
  handleClick = () => { },
  disabled,
  children,
  className,
  style,
  variant,
  title,
  ariaLabel,
  type,
  small,
  to,
  target,
  rel,
  tabIndex = 0,
  forceFocus,
  variantLongArrow,
  leadingIcon,
  numberCounter,
  'data-element-id': dataElementId,
  'data-description': dataDescription,
  forwardRef,
  hideLoadingLabel,
  addStopPropagation,
  showArrowLeft= false,  //show Left arrow in CTA Block in CMS New Pages
  showArrowRight = false, //show Right arrow in CTA Block in CMS New Pages
  showArrowAfterLeft = false,
  showArrowAfterRight = false,
  showArrowAfterUp = false,
  showArrowAfterDown = false,
  showArrowAfterHeadLeft = false,
  showArrowAfterHeadRight = false,
  showArrowAfterHeadUp = false,
  showArrowAfterHeadDown = false,
  showArrowBeforeLeft = false,
  showArrowBeforeRight = false,
  showArrowBeforeUp = false,
  showArrowBeforeDown = false,
  showArrowBeforeHeadLeft = false,
  showArrowBeforeHeadRight = false,
  showArrowBeforeHeadUp = false,
  showArrowBeforeHeadDown = false,
  percentage = -1
}: Props) => {
  
  const showArrowCTABlock = showArrowLeft || showArrowRight;

  const Wrapper =
    variant === 'text-btn' || variant === 'text-btn2'
      ? { component: TextBtnWrapper, 
        props: {
          variantLongArrow,
          leadingIcon,
          showArrowLeft,
          showArrowRight,
          showArrowAfterLeft,
          showArrowAfterRight,
          showArrowAfterUp,
          showArrowAfterDown,
          showArrowAfterHeadLeft,
          showArrowAfterHeadRight,
          showArrowAfterHeadUp,
          showArrowAfterHeadDown,
          showArrowBeforeLeft,
          showArrowBeforeRight,
          showArrowBeforeUp,
          showArrowBeforeDown,
          showArrowBeforeHeadLeft,
          showArrowBeforeHeadRight,
          showArrowBeforeHeadUp,
          showArrowBeforeHeadDown,
        }
       }
      : variant === 'play-btn'
        ? { component: PlayBtnWrapper, props: {} }
        : variant === 'counter-btn'
          ? { component: CounterWrapper, props: { numberCounter } }
          : { component: showArrowCTABlock ? ButtonPrimaryWrapper : React.Fragment, props: showArrowCTABlock ? {showArrowLeft, showArrowRight} : {} };

  const isArrowRounded = showArrowAfterHeadLeft || showArrowAfterHeadRight || showArrowBeforeHeadLeft || showArrowBeforeHeadRight ||
                         showArrowAfterHeadUp || showArrowAfterHeadDown || showArrowBeforeHeadUp || showArrowBeforeHeadDown;

  const classes = clsx('btn-v4', {
    loading: loading,
    [className]: !!className,
    [variant]: !!variant,
    disabled: !!disabled,
    small: small,
    ['force-focus']: forceFocus,
    ['leading-icon']: leadingIcon && !showArrowLeft,
    'arrow-rounded': isArrowRounded,
    "arrow-up-down": showArrowAfterUp || showArrowAfterDown || showArrowBeforeUp || showArrowBeforeDown,
    "arrowed": showArrowCTABlock
  });

  // const [div, size] = useElementSize();

  const Cta = to ? Link : 'button';

  return (
    <Cta
      className={classes}
      onClick={addStopPropagation
        ? (e: any) => { e?.stopPropagation(); handleClick(e)}
        : handleClick}
      disabled={disabled || loading}
      title={title}
      aria-label={ariaLabel}
      style={style}
      type={type}
      to={to ? to : undefined}
      target={target ? target : undefined}
      rel={rel ? rel : undefined}
      tabIndex={tabIndex}
      data-element-id={dataElementId}
      data-description={dataDescription}
      ref={forwardRef}
      aria-hidden={tabIndex < 0 || tabIndex === undefined ? 'true' : undefined}
    >
      {loading ? (
        <LoadingSpinner hideLoadingLabel={hideLoadingLabel} percentage={percentage}/>
      ) : (
        <Wrapper.component {...Wrapper.props}>{children}</Wrapper.component>
      )}
    </Cta>
  );
};

ButtonV2.displayName = 'ButtonV2';

export default ButtonV2;

const LoadingSpinner = ({ hideLoadingLabel, percentage}) => {
  const labels = useLangAuth(['LOADING']);
  return (
    <span className="loading-content">
      <LazySVG
        className="loading-spinner"
        src="/images/spinner/button-v2-spinner.png"
        alt={labels?.LOADING}
      />
      {!hideLoadingLabel && (percentage<0) && <span>{labels?.LOADING?.toUpperCase()}</span>}
      {!hideLoadingLabel &&  (percentage>=0) && <span>{percentage + "% " + labels?.LOADING?.toUpperCase()}</span>}
    </span>
  );
};

const TextBtnWrapper: React.FC<TextBtnWrapperProps> = ({
  children,
  variantLongArrow = false,
  leadingIcon = false,
  // show after arrow
  showArrowAfterLeft = false,
  showArrowAfterRight = false,
  showArrowAfterUp = false,
  showArrowAfterDown = false,
  showArrowAfterHeadLeft = false,
  showArrowAfterHeadRight = false,
  showArrowAfterHeadUp = false,
  showArrowAfterHeadDown = false,
  // show before arrow
  showArrowBeforeLeft = false,
  showArrowBeforeRight = false,
  showArrowBeforeUp = false,
  showArrowBeforeDown = false,
  showArrowBeforeHeadLeft = false,
  showArrowBeforeHeadRight = false,
  showArrowBeforeHeadUp = false,
  showArrowBeforeHeadDown = false,
}) => {
  const beforeArrowIcon = getArrowIcon({
    variantLongArrow,
    showArrowLeft: showArrowBeforeLeft,
    showArrowRight: showArrowBeforeRight,
    showArrowUp: showArrowBeforeUp,
    showArrowDown: showArrowBeforeDown,
    showArrowHeadLeft: showArrowBeforeHeadLeft,
    showArrowHeadRight: showArrowBeforeHeadRight,
    showArrowHeadUp: showArrowBeforeHeadUp,
    showArrowHeadDown: showArrowBeforeHeadDown,
  });

  const afterArrowIcon = getArrowIcon({
    variantLongArrow,
    showArrowLeft: showArrowAfterLeft,
    showArrowRight: showArrowAfterRight,
    showArrowUp: showArrowAfterUp,
    showArrowDown: showArrowAfterDown,
    showArrowHeadLeft: showArrowAfterHeadLeft,
    showArrowHeadRight: showArrowAfterHeadRight,
    showArrowHeadUp: showArrowAfterHeadUp,
    showArrowHeadDown: showArrowAfterHeadDown,
  });

  return (
    <>
      {leadingIcon && beforeArrowIcon}
      <span>{children}</span>
      {!leadingIcon && afterArrowIcon}
    </>
  );
};

const PlayBtnWrapper = ({ children }) => {
  const lang = useSelector(state => state.utils.lang);
  return (
    <>
      <span className="circle">
        <PlayIcon />
      </span>
      <span className="label">
        {children || lang.WATCH_VIDEO}
        {/* <span className="value">{children}</span> */}
      </span>
    </>
  );
};

const CounterWrapper = ({ children, numberCounter = 0 }) => {
  const lang = useSelector(state => state.utils.lang);
  return (
    <>
      <div className='counter-label'>
        {children}
      </div>
      <div className="number-counter">
        <span>{numberCounter}</span>
      </div>
    </>
  );
};

const ButtonPrimaryWrapper = ({ children, showArrowLeft = false, showArrowRight = false }) => {
  return (
    <>
      {showArrowLeft && <ArrowLeftIcon/> }
      <span>{children}</span>
      {showArrowRight && <ArrowRightIcon/> }
    </>
  );
};

