import { HeadingProps } from "@components/CMSNewPages/components/Heading";
import { EAlign, TAlign } from "@components/CMSNewPages/components/Align";
import { BannerSmallProps } from "@components/CMSNewPages/components/BannerSmall";
import { SingleContentProps, TSingleContent } from "@components/CMSNewPages/components/SingleContent";
import { QuoteProps } from "./components/Quote";
import { ImageTextProps } from "./components/ImageText";
import { TabContentModuleProps } from "@components/CMSNewPages/components/TabContentModule/TabContentModule";
import { BrandCarouselProps, TBrand } from "@components/CMSNewPages/components/BrandCarousel";
import { AvatarCarouselProps } from "@components/CMSNewPages/components/AvatarCarousel";
import { TAvatar } from "@components/CMSNewPages/components/Avatar";
import {
	ERowLayout,
	MRowLayout,
	TCMSComponent,
	TCMSLink,
	TCMSRow,
	TCMSStructure,
	TDynamicHeader,
	TCMSComponentVariant,
	EMargin,
	TCMSSubjectTaxonomy,
	ECTAComponentIcon,
} from "@components/CMSNewPages/CMSTypes";
import { TTopic } from "@components/CMSNewPages/components/TabContentModule/Topic";
import { SocialMediaProps } from "./components/SocialMediaCard";
import { HeroBannerMediaProps } from "./components/HeroBannerMedia";
import { CrossLinkProps, TCrossLink } from "./components/CrossLink";
import { ReviewProps, TReview } from "./components/Review";
import { ContentQueueProps, TCardContentQueue } from "./components/ContentQueue";
import { InsightsProps, TInsight } from "./components/Insights";
import { CardListProps, TCardList } from "./components/CardList";
import { CMS_NEW_PAGE } from "@utility/const";
import { CardCarouselProps, TCardCarousel } from "./components/CardCarousel";
import { ContentCarouselProps } from "@components/CMSNewPages/components/ContentCarousel";
import { FullPageMediaProps } from "./components/FullPageMedia";
import { CMS_TITLE_COMPONENT_KEYS } from "@components/CMSNewPages/CMSEngine";
import {
	ECMSMultimediaContentType,
	TCMSRawItem,
	TCMSRawPlacement,
	TCMSRawStructure
} from "@components/CMSNewPages/CMSTypeResponse";
import { ECTATypes } from "./components/CTA";
import { TCardListCatalog } from "./components/CatalogIntegration/CardListCatalog";
import { Course } from "@model/CoursesClass";
import { TExpert } from "./components/Expert";


export function getValueFromLayoutVariant(layoutVariant: Array<Record<'name', any>>, nameLayout: string) {
	return layoutVariant?.find(layout => layout.name.toString().includes(nameLayout))?.name
}

export function getAlignFromVariant(variantName: string | undefined): EAlign {
	if (!variantName) {
		return EAlign.LEFT;
	}

	const variantParts = variantName.split('_');
	const alignPart = variantParts.pop().toLowerCase();

	if (Object.values(EAlign).includes(alignPart as EAlign)) {
		return alignPart as EAlign;
	}

	return EAlign.LEFT; 
}

export function getTargetLinks(link: string | null): "_self" | "_blank" {
	const relativePathRegex = /^(?!www\.|(?:http|ftp)s?:\/\/|[A-Za-z]:\\|\/\/).*/;
	let target: "_self" | "_blank" = "_self";

	if (relativePathRegex.test(link) === true) {
		target = "_self";
	} else {
		target = "_blank";
	}
	return target;
}


export function getComponentIconVariant(componentIcon){
	switch(componentIcon){
		case ECTATypes.BUTTON_ARROW_RIGHT 	:{ return "showArrowRight"}
		case ECTATypes.BUTTON_ARROW_LEFT 	:{ return "showArrowLeft"}
		case ECTATypes.LINK         		:{ return "text-btn-no-arrow" }
		case ECTAComponentIcon.ARROW_BEFORE_RIGHT   	:{ return "showArrowBeforRight"}
		case ECTAComponentIcon.ARROW_BEFORE_LEFT  	:{ return "showArrowBeforeLeft"}
		case ECTAComponentIcon.ARROW_BEFORE_UP   	:{ return "showArrowBeforeUp"}
		case ECTAComponentIcon.ARROW_BEFORE_DOWN   	:{ return "showArrowBeforeDown" }
		case ECTAComponentIcon.ARROW_BEFORE_HEAD_RIGHT   	:{ return "showArrowBeforeHeadRight"}
		case ECTAComponentIcon.ARROW_BEFORE_HEAD_LEFT   	:{ return "showArrowBeforeHeadLeft"}
		case ECTAComponentIcon.ARROW_BEFORE_HEAD_UP   	:{ return "showArrowBeforeHeadUp"}
		case ECTAComponentIcon.ARROW_BEFORE_HEAD_DOWN   	:{ return "showArrowBeforeHeadDown"}
		case ECTAComponentIcon.ARROW_AFTER_RIGHT   	:{ return "showArrowAfterRight"}
		case ECTAComponentIcon.ARROW_AFTER_LEFT   	:{ return "showArrowAfterLeft"}
		case ECTAComponentIcon.ARROW_AFTER_UP  	:{ return "showArrowAfterUp" }
		case ECTAComponentIcon.ARROW_AFTER_DOWN  	:{ return "showArrowAfterDown"}
		case ECTAComponentIcon.ARROW_AFTER_HEAD_RIGHT   	:{ return "showArrowAfterHeadRight"}
		case ECTAComponentIcon.ARROW_AFTER_HEAD_LEFT   	:{ return "showArrowAfterHeadLeft"}
		case ECTAComponentIcon.ARROW_AFTER_HEAD_UP  	:{ return "showArrowAfterHeadUp"}
		case ECTAComponentIcon.ARROW_AFTER_HEAD_DOWN  	:{ return "showArrowAfterHeadDown"}
		default: {return ""}
	}

}



export function getLayoutButton(variantName: string | undefined, componentIcon: ECTAComponentIcon = ECTAComponentIcon.NO_ICON ): ECTATypes {
	
	const componentIconSwitchButton = () => {
		switch(componentIcon){
			case ECTAComponentIcon.ARROW_DX: { return ECTATypes.BUTTON_ARROW_RIGHT }
			case ECTAComponentIcon.ARROW_SX: { return ECTATypes.BUTTON_ARROW_LEFT }
			default: { return ECTATypes.BUTTON }
		}
	}

	const componentIconSwitchLink = () => {
		switch(componentIcon){
			//Before arrows
			case ECTAComponentIcon.ARROW_BEFORE_RIGHT: { return ECTATypes.LINK_ARROW_BEFORE_RIGHT }
			case ECTAComponentIcon.ARROW_BEFORE_LEFT: { return ECTATypes.LINK_ARROW_BEFORE_LEFT }
			case ECTAComponentIcon.ARROW_BEFORE_UP: { return ECTATypes.LINK_ARROW_BEFORE_UP }
			case ECTAComponentIcon.ARROW_BEFORE_DOWN: { return ECTATypes.LINK_ARROW_BEFORE_DOWN }
			case ECTAComponentIcon.ARROW_BEFORE_HEAD_RIGHT: { return ECTATypes.LINK_ARROW_BEFORE_HEAD_RIGHT }
			case ECTAComponentIcon.ARROW_BEFORE_HEAD_LEFT: { return ECTATypes.LINK_ARROW_BEFORE_HEAD_LEFT }
			case ECTAComponentIcon.ARROW_BEFORE_HEAD_UP: { return ECTATypes.LINK_ARROW_BEFORE_HEAD_UP }
			case ECTAComponentIcon.ARROW_BEFORE_HEAD_DOWN: { return ECTATypes.LINK_ARROW_BEFORE_HEAD_DOWN }
			//After arrows
			case ECTAComponentIcon.ARROW_AFTER_RIGHT: { return ECTATypes.LINK_ARROW_AFTER_RIGHT }
			case ECTAComponentIcon.ARROW_AFTER_LEFT: { return ECTATypes.LINK_ARROW_AFTER_LEFT }
			case ECTAComponentIcon.ARROW_AFTER_UP: { return ECTATypes.LINK_ARROW_AFTER_UP }
			case ECTAComponentIcon.ARROW_AFTER_DOWN: { return ECTATypes.LINK_ARROW_AFTER_DOWN }
			case ECTAComponentIcon.ARROW_AFTER_HEAD_RIGHT: { return ECTATypes.LINK_ARROW_AFTER_HEAD_RIGHT }
			case ECTAComponentIcon.ARROW_AFTER_HEAD_LEFT: { return ECTATypes.LINK_ARROW_AFTER_HEAD_LEFT }
			case ECTAComponentIcon.ARROW_AFTER_HEAD_UP: { return ECTATypes.LINK_ARROW_AFTER_HEAD_UP }
			case ECTAComponentIcon.ARROW_AFTER_HEAD_DOWN: { return ECTATypes.LINK_ARROW_AFTER_HEAD_DOWN }

			default: { return ECTATypes.LINK }
		}
	}

	if (!variantName) {
		return componentIconSwitchButton();
	}

	const variantParts = variantName.split('_');
	const layoutBtnPart = variantParts.pop();

	if (layoutBtnPart) {
		switch(layoutBtnPart){
			case "Link"		: { return componentIconSwitchLink() }
			case "Button"	: { return componentIconSwitchButton() }
			default: {
				if (Object.values(ECTATypes).includes(layoutBtnPart as ECTATypes))  return layoutBtnPart as ECTATypes;
			}
		}
	}

	return ECTATypes.BUTTON;
}

function mapProps<TypeProps>(props: any) {
	const alignment: TAlign = getAlignFromVariant(props.layoutVariant?.[0]?.name);

	return {
		...props,
		id: props.id ?? null,
		title: props.title ?? null,
		description: props.description ?? null,
		backgroundColor: props.backgroundColour ?? null,
		backgroundImage: props.multiMediaType?.[0]?.data?.uri ?? null,
		align: alignment,
		target: getTargetLinks(props?.linkString) ?? null,
	} as TypeProps;
}

function mapPropsCatalog<TypeProps>(props: any) {
	const alignment: TAlign = getAlignFromVariant(props.layoutVariant?.[0]?.name);

	return {
		...props,
		id: props.id ?? null,
		title: props.title ?? null,
		description: props.description ?? null,
		backgroundColor: props.backgroundColour ?? null,
		backgroundImage: props.multiMediaType?.[0]?.data?.uri ?? null,
		align: alignment,
		target: getTargetLinks(props?.linkString) ?? null,
	} as TypeProps;
}


/**
 * @see Heading.tsx
 * @example {
 *   title: "Titolo",
 *   description: "Descrizione",
 *   layoutVariant: [name: "[Tipo componente]", "anchorable": true]
 * }
 * */
const mapHeadingProps = (props: TCMSRawItem & Partial<TCMSComponent>): HeadingProps => {

	const propsMapped = mapProps<HeadingProps>(props)
	const variants = new Set<TCMSComponentVariant>()
	/*if(props.variants.has('')){

	}*/

	return {
		...propsMapped, /*title, description, backgroundColor, backgroundImage, align*/
	}
}

/**
 * @see BannerSmall.tsx
 * @example {
 *   title: "Titolo",
 *   description: "Descrizione",
 *   layoutVariant: {name: "align-sx"}
 * }
 * */
const mapBannerSmallProps = (props: TCMSRawItem): BannerSmallProps => {

	const propsMapped = mapProps<BannerSmallProps>(props)

	return {
		...propsMapped, /*title, description, backgroundColor, backgroundImage, align*/
		ctaAction: props?.linkString ?? null,
		ctaLabel: props?.linkLabel ?? null,
		target: getTargetLinks(props?.linkString) ?? null,
		idImage: props?.multiMediaType?.[0]?.id ?? null
	}
}

const mapSingleContentProps = (props: TCMSRawItem): SingleContentProps => {
	const propsMapped = mapProps<SingleContentProps>(props)

	return {
		...propsMapped, //title, description, backgroundColor, backgroundImage, align
		src: props?.multiMediaType?.[0]?.data?.uri ?? null,
		contentType: props?.multiMediaType?.[0]?.data?.contentType,
		idImage: props?.multiMediaType?.[0]?.id
	}
}

const mapContentCarouselProps = (props: TCMSRawItem): ContentCarouselProps => {
	const propsMapped = mapProps<ContentCarouselProps>(props)
	const carousel: Array<TSingleContent> = []

	props?.items?.forEach(item => {
		carousel.push({
			src: item?.multiMediaType?.[0]?.data?.uri,
			contentType: item?.multiMediaType?.[0]?.data?.contentType,
			description: item?.description || null
		} as TSingleContent)
	})

	return {
		...propsMapped, //title, description, backgroundColor, backgroundImage, align
		items: carousel
	}
}

const mapQuoteProps = (props: TCMSRawItem): QuoteProps => {
	const propsMapped = mapProps<QuoteProps>(props)

	return {
		...propsMapped, //title, description, backgroundColor, backgroundImage, align
		title: props?.description ?? null,
		description: props?.description2 ?? null,
		ctaAction: props?.linkString ?? null,
		ctaLabel: props?.linkLabel ?? null,
		target: getTargetLinks(props?.linkString) ?? null,
	}
}

const mapImageTextProps = (props: TCMSRawItem): ImageTextProps => {
	const propsMapped = mapProps<ImageTextProps>(props)

	return {
		...propsMapped, //title, description, backgroundColor , align
		ctaAction: props?.linkString ?? null,
		ctaLabel: props?.linkLabel ?? null,
		target: getTargetLinks(props?.linkString) ?? null,
		src: props?.multiMediaType?.[0]?.data?.uri ?? null,
		idImage: props?.multiMediaType?.[0]?.id ?? null,
		contentType: props?.multiMediaType?.[0]?.data?.contentType ?? ECMSMultimediaContentType.JPEG,
		// loop: props?.playerSettings?.loop,
		// muted: props?.playerSettings?.muted,
	}
}

const mapSocialMediaProps = (props: TCMSRawItem): SocialMediaProps => {
	const propsMapped = mapProps<SocialMediaProps>(props)

	return {
		...propsMapped,
		ctaAction: props?.linkString ?? null,
		ctaLabel: props?.linkLabel ?? null,
		target: getTargetLinks(props?.linkString) ?? null,
		layoutButton: getLayoutButton(props.layoutVariant?.[0]?.name) ?? null,
		src: props?.multiMediaType?.[0]?.data?.uri ?? null,
		contentType: props?.multiMediaType?.[0]?.data?.contentType,
		idImage: props?.multiMediaType?.[0]?.id,
		autoPlay: true,
		loop: true,
		muted: true,
	}
}

const mapHeroBannerMediaProps = (props: TCMSRawItem): HeroBannerMediaProps => {
	const propsMapped = mapProps<HeroBannerMediaProps>(props)
	return {
		...propsMapped,
		//texts
		title: props?.description ?? null,
		description: props?.description2 ?? null,
		textCtaPlayVideo: props?.title ?? null,

		// cta
		ctaAction: props?.linkString ?? null,
		ctaLabel: props?.linkLabel ?? null,
		target: getTargetLinks(props?.linkString) ?? null,

		// video background

		imageVideoId: props?.multiMediaType?.[0]?.id ?? null,
		src: props?.multiMediaType?.[0]?.data?.uri ?? null,
		contentType: props?.multiMediaType?.[0]?.data?.contentType ?? ECMSMultimediaContentType.VIDEO,
		loop: true,
		autoPlay: true,
		muted: true,
		hideControls: true,
		multiMediaType: props?.multiMediaType ?? null,

		//video in modal
		videoModalAttributes: {
			videoFullId: props.multiMediaType2?.[0]?.id ?? null,
			src: props?.multiMediaType2?.[0]?.data?.uri ?? null,
			contentType: props?.multiMediaType2?.[0]?.data?.contentType ?? ECMSMultimediaContentType.VIDEO,
			autoPlay: true,
			hideControls: true,
			loop: false,
			muted: false
		}
	}
}

const mapFullPageMediaProps = (props: TCMSRawItem): FullPageMediaProps => {
	const propsMapped = mapProps<FullPageMediaProps>(props)

	return {
		...propsMapped,
		ctaAction: props?.linkString ?? null,
		ctaLabel: props?.linkLabel ?? null,
		target: getTargetLinks(props?.linkString) ?? null,
		layoutButton: getLayoutButton(props.layoutVariant?.[0]?.name) ?? null,
		src: props?.multiMediaType?.[0]?.data?.uri ?? null,
		contentType: props?.multiMediaType?.[0]?.data?.contentType,
		idImage: props?.multiMediaType?.[0]?.id,
		idVideo: props?.multiMediaType?.[0]?.id,
		autoPlay: true,
		loop: true,
		muted: true,
	}
}

const mapCrossLinkProps = (props: TCMSRawItem): CrossLinkProps => {
	const propsMapped = mapProps<CrossLinkProps>(props)

	const links: Array<TCrossLink> = []
	
	props.items?.forEach(link => {
		links.push({
			ctaAction: link?.linkString ?? null,
			ctaLabel: link?.linkLabel ?? null,
			target: getTargetLinks(link?.linkString) ?? null,
			layoutButton: getLayoutButton(props.layoutVariant?.[0].name, link?.componentIcon as ECTAComponentIcon),
			idLink: link?.id ?? null,
			linkTarget: link?.linkTargets?.[0] ?? null
		})
	})

	return {
		...propsMapped,
		links: links
	}
}

const mapContentQueueProps = (props: TCMSRawItem): ContentQueueProps => {
	const propsMapped = mapProps<ContentQueueProps>(props)
	const cards: Array<TCardContentQueue> = []
	props.items?.forEach(card => {
		cards.push({
			id: card?.id ?? null,
			title: card?.description ?? null,
			description: card?.description2 ?? null,
			complexity: card?.title,
			badges: card?.chipLabels,

			thumbnail: {
				src: card?.multiMediaType?.[0]?.data?.uri,
				contentType: card?.multiMediaType?.[0]?.data?.contentType,
				thumbnailId: card?.multiMediaType?.[0]?.id
			},

			src: card?.multiMediaType2?.[0]?.data?.uri ?? card?.multiMediaType?.[0]?.data?.uri,
			contentType: card?.multiMediaType2?.[0]?.data?.contentType ?? card?.multiMediaType?.[0].data?.contentType,
			multiMediaId: card?.multiMediaType2?.[0]?.id ?? card?.multiMediaType?.[0]?.id,
			loop: false,
			hideControls: true,
			autoPlay: false,
			muted: false

		} as TCardContentQueue)

	});

	return {
		...propsMapped, // title, description
		title: props?.collectionTitle ?? null,
		description: props?.collectionSubTitle ?? null,
		cards: cards,
	}
}

const mapTabContentModuleProps = (props: TCMSRawItem): TabContentModuleProps => {
	const propsMapped = mapProps<TabContentModuleProps>(props)
	const topics: Array<TTopic> = []
	props?.items?.forEach(topic => {
		topics.push({
			id: topic?.id,
			titleChip: topic?.title,
			complexity: topic?.description2,
			description: topic?.description,
			link: { linkLabel: topic?.linkLabel, linkString: topic?.linkString },
			target: getTargetLinks(topic?.linkString) ?? null,
			image: topic?.multiMediaType?.[0]?.data?.uri,
			idImage: topic?.multiMediaType?.[0]?.id,
			backgroundColor: topic?.backgroundColour ?? null,
		} as TTopic)
	})
	delete propsMapped["items"]
	return {
		...propsMapped, //title, description, backgroundColor , align
		title: props?.collectionTitle ?? null,
		topics: topics
	}
}

const mapBrandCarouselProps = (props: TCMSRawItem): BrandCarouselProps => {
	const propsMapped = mapProps<BrandCarouselProps>(props)
	const brands: Array<TBrand> = []
	props?.items?.forEach(brand => {
		brands.push({
			id: brand?.id,
			imageURL: brand?.multiMediaType?.[0]?.data?.uri,
			linkString: brand?.linkString ?? null
		} as TBrand)
	})
	delete propsMapped["items"]
	return {
		...propsMapped, //title, description, backgroundColor , align
		title: props?.collectionTitle ?? null,
		brands: brands
	}
}

const mapAvatarCarouselProps = (props: TCMSRawItem): AvatarCarouselProps => {
	const propsMapped = mapProps<AvatarCarouselProps>(props)
	const avatars: Array<TAvatar> = []
	let link: TCMSLink = null

	const allAvatarsHaveLongDescr = props?.items?.every((avatar) => avatar?.description2 !== null && avatar?.description2 !== '');


	// neither linkString nor linkLabel aren't filled, link See all is not visible
	if (props?.linkString && props?.linkLabel)
		link = { linkLabel: props?.linkLabel, linkString: props?.linkString }

	props?.items?.forEach(avatar => {
		avatars.push({
			id: avatar?.id,
			name: avatar?.title,
			description: avatar?.description,
			longDescription: avatar?.description2 ?? null,
			imageURL: avatar?.multiMediaType?.[0]?.data?.uri,
			idImage: avatar?.multiMediaType?.[0]?.id,
			linkLabel: avatar?.linkLabel,
			linkString: avatar?.linkString,
			target: getTargetLinks(avatar?.linkString) ?? null
		} as TAvatar)
	})
	delete propsMapped["items"]
	
	const layoutVariantLeft = !!getValueFromLayoutVariant(props?.layoutVariant, "Left");
	const finalAlign = layoutVariantLeft ? EAlign.LEFT : EAlign.CENTER;

	return {
		...propsMapped, //title, description, backgroundColor , align
		title: props?.collectionTitle ?? null,
		avatars: avatars,
		linkLabel: props?.teaserTargetList?.[0]?.title,
		linkString: props?.teaserTargetList?.[0]?.linkUrl,
		target: props?.teaserTargetList?.[0]?.targetWindow,
		allAvatarsHaveLongDescr,
		align: finalAlign
	}
}

const mapExpertsListProps = (props: TCMSRawItem): AvatarCarouselProps => {
  const propsMapped = mapProps<AvatarCarouselProps>(props);
  let link: TCMSLink = null;
  const allAvatarsHaveLongDescr = props?.items?.every(
    avatar => avatar?.description2 !== null && avatar?.description2 !== ''
  );
  // neither linkString nor linkLabel aren't filled, link See all is not visible
  if (props?.linkString && props?.linkLabel)
    link = { linkLabel: props?.linkLabel, linkString: props?.linkString };
  const avatars = props?.items?.map(avatar => {
	const targetLinks = avatar?.linkTargets?.map((target, index) => ({
	  label: target?.title,
	  url: target?.linkUrl,
	  courseID: target?.courseId,
	  target: target?.targetWindow,
	  componentIcon: getComponentIconVariant(target?.componentIcon),
	  // Se si vuole replicare il comportamento originale: il primo link usa l'indice 0, il secondo l'indice 1.
	  image: target?.pictureAndMedia?.[index]?.data?.uri,
	  linkModal: target?.linkUrl,
	})) || [];
  
	return {
	  id: avatar?.id,
	  name: avatar?.title,
	  description: avatar?.description,
	  imageURL: avatar?.multiMediaType?.[0]?.data?.uri,
	  idImage: avatar?.multiMediaType?.[0]?.id,
	  // Modal
	  ctaModal: avatar?.linkLabel,
	  longDescription: avatar?.description2 ?? null,
	  actionModal: avatar?.linkUrl,
	  // Invece di first e second CTA, usiamo targetLinks
	  targetLinks,
	} as TExpert;
  });
  
  delete propsMapped['items'];

  const layoutVariantLeft = !!getValueFromLayoutVariant(props?.layoutVariant, 'Left');
  const finalAlign = layoutVariantLeft ? EAlign.LEFT : EAlign.CENTER;

  return {
    ...propsMapped, //title, description, backgroundColor , align
    title: props?.collectionTitle ?? null,
    avatars: avatars,
    linkLabel: props?.teaserTargetList?.[0]?.title,
    linkString: props?.teaserTargetList?.[0]?.linkUrl,
    target: props?.teaserTargetList?.[0]?.targetWindow,
    allAvatarsHaveLongDescr,
    align: finalAlign,
  };
};


const mapInsightsProps = (props: TCMSRawItem): InsightsProps => {

	const propsMapped = mapProps<InsightsProps>(props)
	const insights: Array<TInsight> = []

	props?.items?.forEach(insight => {

		insights.push({
			id: insight?.id ?? null,
			title: insight?.title ?? null,
			description: insight?.description ?? null,
			imageContent: insight?.imageContent ?? null
		} as TInsight)
	})

	return {
		...propsMapped, //title, description, backgroundColor , align
		insights: insights,
	}
}

const mapReviewProps = (props): ReviewProps => {
	const propsMapped = mapProps<ReviewProps>(props)
	const reviews: Array<TReview> = []

	const hasImage = props?.items?.every((review) => review?.multiMediaType?.[0]?.data?.uri != null && review?.multiMediaType?.[0]?.data?.uri !== '');

	props?.items?.map(review => {
		reviews.push({
			id: review?.id ?? null,
			ctaAction: review?.linkString ?? null,
			ctaLabel: review?.linkLabel ?? null,
			target: getTargetLinks(review?.linkString) ?? null,
			review: review.description,
			reviewer: review?.title ?? null,
			role: review?.description2 ?? null,
			src: review?.multiMediaType?.[0]?.data?.uri ?? null,
			hasImage
		} as TReview)
	})
	return {
		...propsMapped,
		reviews: reviews,
	}
}

const mapCardListProps = (props): CardListProps => {
	const propsMapped = mapProps<CardListProps>(props)
	const cards: Array<TCardList> = []

	props?.items?.forEach(card => {
		cards.push({
			id: card?.id,
			title: card?.description,
			tag: card?.chipLabels,
			complexity: card?.title,
			price: card?.description2,
			imageURL: card?.multiMediaType?.[0]?.data?.uri,
			idImage: card?.multiMediaType?.[0]?.id,
			ctaAction: card?.linkString ?? null,
			target: getTargetLinks(card?.linkString) ?? null,
		} as TCardList)
	})

	return {
		...propsMapped,
		cards: cards
	}
}

const mapCardListCatalogProps = (props: TCMSRawItem)=> {
	const propsMapped = mapProps<CardListProps>(props)
	// return sorted courses
	const cards: Array<TCardCarousel> = props?.catalog?.sort((a,b) => a?.order - b?.order)
	
	return {
		...propsMapped,
		cards: cards
	}
}

const mapCardCarouselCatalogProps = (props: TCMSRawItem): CardCarouselProps => {
	const propsMapped = mapPropsCatalog<CardCarouselProps>(props)
	// return sorted courses
	const cards: Array<TCardCarousel> = props?.catalog?.sort((a,b) => a?.order - b?.order)


	return {
		...propsMapped,
		title: props?.collectionTitle ?? null,
		description: props?.collectionSubTitle ?? null,
		linkString: props?.teaserTargetList?.[0]?.linkUrl ?? null,
		linkLabel: props?.teaserTargetList?.[0]?.title ?? null,
		target: props?.teaserTargetList?.[0]?.targetWindow ?? null,
		cards: cards
	}
}

const mapCardCarouselProps = (props: TCMSRawItem): CardCarouselProps => {
	const propsMapped = mapProps<CardCarouselProps>(props)
	const cards: Array<TCardCarousel> = []

	props?.items?.forEach(card => {
		cards.push({
			id: card?.id,
			courseFullName: card?.description,
			badge: card?.chipLabels?.join(""),
			complexity: card?.title,
			price: card?.description2,
			courseOverviewFile: card?.multiMediaType?.[0]?.data?.uri,
			courseOverviewFileType: card?.multiMediaType?.[0]?.data?.contentType,
			// previewVideo: card?.multiMediaType2?.[0]?.data?.uri,
			linkString: card?.linkString,
			target: getTargetLinks(card?.linkString) ?? null,
			duration: card?.duration
		} as TCardCarousel)
	})

	return {
		...propsMapped,
		title: props?.collectionTitle ?? null,
		description: props?.collectionSubTitle ?? null,
		linkString: props?.teaserTargetList?.[0]?.linkUrl ?? null,
		linkLabel: props?.teaserTargetList?.[0]?.title ?? null,
		target: props?.teaserTargetList?.[0]?.targetWindow ?? null,
		cards: cards
	}
}

/**
 *  check if there is an item into a row, flagging that row as anchorable
 *  in case of true return name of link to set inside Dynamic Header
*/
function isRowAnchorable(items: Array<any>): [boolean, string] {
	for (const item of items) {
		if (getValueFromLayoutVariant(item?.layoutVariant, CMS_NEW_PAGE.LAYOUT_ANCHORABLE_ROW)) {
			return [true, item?.title]
		}
	}
	return [false, null]
}

/**
 *   marginBottom row
*/

const getMarginFromCMS = (margin: EMargin): string => {
	switch (margin) {
		case EMargin.MARGIN_S:
			return '20px';
		case EMargin.MARGIN_M:
			return '40px';
		case EMargin.MARGIN_L:
			return '60px';
		case EMargin.MARGIN_XL:
			return '80px';
		case EMargin.MARGIN_X:
			return '0px';
		default:
			return '0px';
	}
}

/**
 * Map the structure from rawData response of:
 * -  CMS_NEW_PAGES_URLS.GET_STRUCTURE_PRIVATE or
 * -  CMS_NEW_PAGES_URLS.GET_STRUCTURE_PRIVATE
 *
 * @params {object} rawData
 *
 *
 * @see CMS_NEW_PAGES_URLS
 * @example No page found with that slug (or segment)
 * {
 *     "data": {
 *         "content": {
 *             "pageByPath": null
 *         }
 *     }
 * }
 *
 * @example Structure of page
 * {
 *     "data": {
 *         "content": {
 *             "pageByPath": {
 *               "grid": {
 *                 "rows": [
 *                   { placements: [
 *                     {
 *                       "viewtype": "default",
 *                       "name": "row4",
 *                       "items": [
 *                         {
 *                         "id": "368110",
 *                         "name": "anchorable - Certificate programs",
 *                         "type": "CMYLCustomArticle",
 *                         "title": "Certificate programs",
 *                         "layoutVariant": [ {"name": "anchorable" }]
 *													},
 *                          ...items,
 *                    { placements: {$rowinfo} },
 *                 ]
 *               }
 *             }
 *         }
 *    }
 * }
 * */
const mapStructureCMSNewPage = (rawData: TCMSRawStructure): TCMSStructure | null => {
	const convertNameToHashableId = name => {
		const id = name.toString().toLowerCase().replace(" ", "-")
		return [id, '#' + id]
	}


	const page = rawData.data.content.pageByPath ?? null
	const rows: Array<TCMSRow> = []
	const dynamicHeader: TDynamicHeader = []


	// in case no page with that specified slug is found → 404.
	if (page === null) return null
	page.grid.rows.forEach(row => {
		const dataRow: TCMSRawPlacement = row.placements[0]

		// If dataRow.items is empty or undefined, skip the rest of the loop
		if (!dataRow?.items || dataRow.items.length === 0) {
			return;
		}

		const [idRow, idRowHashed] = convertNameToHashableId(dataRow?.name) // example [row1, #row1]
		const items = dataRow?.items
		const [isAnchorable, titleLinkOfRowAnchorable] = isRowAnchorable(items)
		const bHasTitle = items.some(item => getValueFromLayoutVariant(item.layoutVariant, CMS_NEW_PAGE.LAYOUT_ANCHORABLE_ROW))

		rows.push({
			// name and id to refer with header dynamic
			id: idRow,
			name: dataRow?.name,

			// layout of row (split to 50+50, 33+33+33, 25*4 or default 100%)
			layout: MRowLayout.get(dataRow.viewtype) ?? ERowLayout.DEFAULT,

			// if true force loading even if is not on screen
			anchorable: isAnchorable,

			// rawData of items to be mapped properly for specific CMSComponent by the engine
			components: items,

			// row has title?
			hasTitle: bHasTitle,

			// margin bottom
			margin: getMarginFromCMS(dataRow.margin) ?? EMargin.MARGIN_X
		} as TCMSRow)

		// save as row hashable
		if (isAnchorable) dynamicHeader.push({ linkLabel: titleLinkOfRowAnchorable, linkString: idRow })

	});

	return {
		idPage: page?.id,
		segment: null,
		pageTitle: page?.title,
		htmlTitle: page?.htmlTitle,
		htmlDescription: page?.htmlDescription,
		// pageName      : page?.name, // TODO maybe useless
		rows: rows,
		dynamicHeader: dynamicHeader.slice(0, CMS_NEW_PAGE.MAX_ROW_ANCHORABLE), // max 4 Header item
		dynamicButtonHeader: page?.subjectTaxonomy
	} as TCMSStructure
}

/**
 * TEMPLATE
 * @example
 *
 *
 * const map[ComponentName]Props = (props: TCMSLayoutItem): [ComponentName]Props=>{
 * 	const propsMapped = mapProps<[ComponentName]Props>(props)
 *
 * 	return {
 * 		...propsMapped, //title, description, backgroundColor, backgroundImage, align
 * 	[props to map]
 *  }
 * }
 * */

/**
 * TEMPLATE
 * @example
 *
 *
 * const map[ComponentName]Props = (props: TCMSLayoutItem): [ComponentName]Props=>{
 * 	const propsMapped = mapProps<[ComponentName]Props>(props)
 *
 * 	return {
 * 		...propsMapped, //title, description, backgroundColor, backgroundImage, align
 * 	[props to map]
 *  }
 * }
 * */
export {
	mapStructureCMSNewPage, /*Mapping Structure in to rows and build dynamic header*/
	mapHeroBannerMediaProps, /* 01 - HeroBannerMedia component */
	mapHeadingProps, /* 02 - Heading component */
	mapInsightsProps, /* 03 - Insights component */
	mapTabContentModuleProps, /* 04 - TabContentModule component*/
	mapContentQueueProps, /* 05 - ContentQueue component*/
	mapAvatarCarouselProps, /* 06 - AvatarCarousel component*/
	mapBrandCarouselProps, /* 07 - BrandCarousel component*/
	mapBannerSmallProps, /* 08 - BannerSmall component */
	mapSocialMediaProps, /* 09 - SocialMediaCard component */
	mapReviewProps, /* 10 - Review component */
	mapQuoteProps, /* 11 - Quote component */
	mapCrossLinkProps, /* 12 - CrossLink component */
	mapContentCarouselProps, /* 13 - ContentCarousel component */
	mapSingleContentProps, /* 14 - SingleContent component */
	mapImageTextProps, /* 15 - ImageText component */
	mapCardListProps, /* 16 - Cards List component */
	mapCardCarouselProps, /* 17 - Cards Carousel component */
	mapFullPageMediaProps, /* 18 - FullPageMedia component */
	mapCardListCatalogProps, /* 19 - CardListCatalog component */
	mapCardCarouselCatalogProps /* 20 - CardCarouselCatalog component */, mapExpertsListProps
};