import { useState, useEffect, useMemo } from 'react';
import { Course, CoursesMap } from '@model/CoursesClass';
import {
  getOnboardingCourseFromCoursesMap,
  isCourseOnBoarding,
} from '@utility/onBoardingCourseUtility';
import { useSelector } from '@hooks/redux';

const useGetOnboardingCourse = (id: string = null): Course => {
  const coursesMap: CoursesMap = useSelector(state => state.course.coursesMap);
    
  const [onboardingCourse, setOnboardingCourse] = useState<Course>(null);

  useEffect(() => {
    let onboardingCourseTemp = null;

    if (coursesMap && !id) {
      onboardingCourseTemp = getOnboardingCourseFromCoursesMap(coursesMap, true);
    }

    if (coursesMap && id) {
      const course = coursesMap[id];
      if (isCourseOnBoarding(course)) {
        onboardingCourseTemp = course;
      }
    }

    setOnboardingCourse(onboardingCourseTemp);
  }, [coursesMap]);

  return onboardingCourse;

};
export default useGetOnboardingCourse;

//old optimization
// // Use useMemo to memoize the result based on coursesMap and id
  // const onboardingCourse = useMemo(() => {
  //   if (coursesMap) {
  //     if (!id) {
  //       return getOnboardingCourseFromCoursesMap(coursesMap, true);
  //     }

  //     const course = coursesMap[id];
  //     if (course && isCourseOnBoarding(course)) {
  //       return course;
  //     }
  //   }

  //   // Return null if coursesMap or id is falsy
  //   return null;
  // }, [coursesMap, id]);